<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <div class="flex flex-row gap-2 align-items-center text-800 mb-1">
                    <AppInfoManual nomeTelaDoManual="medicos-list" />
                    Listagem de médicos
                </div>
                <div class="flex flex-column mb-4 md:flex-row md:justify-content-between md:align-items-center">
                    <div class="p-fluid mt-2 w-full md:mt-0 md:w-5">
                        <Chips v-model="filter" placeholder="Pesquisar" @add="load" @remove="load" />
                    </div>
                    <div class="mt-2 ml-auto md:mt-0">
                        <Button label="Novo" icon="pi pi-plus" class="p-button p-button-text mr-2" @click="$router.push('/medic/new')" />
                    </div>
                </div>

                <Toast />
                <AppLoadingWrapper v-if="loading" />
                <DataTable
                    v-if="!loading"
                    dataKey="id"
                    :value="records"
                    :row-hover="true"
                    class="p-datatable-sm"
                    responsiveLayout="stack"
                    breakpoint="640px"
                >
                    <template #empty> Nenhum registro encontrado. </template>

                    <template #loading> Carregando registros. Aguarde ... </template>

                    <Column :sortable="true" field="name" header="Nome"></Column>

                    <Column :sortable="true" field="classCouncil" header="Conselho de Classe">
                        <template #body="slotProps">
                            <div :title="slotProps.data.classCouncil">
                                {{ slotProps.data.classCouncilAcronym }}
                            </div>
                        </template>
                    </Column>

                    <Column :sortable="true" field="specialty" header="Especialidade">
                        <template #body="slotProps">
                            <Chip :label="slotProps.data.specialty" class="px-3 text-sm" />
                        </template>
                    </Column>

                    <Column bodyClass="text-right" headerStyle="width: 10rem;">
                        <template #body="slotProps">
                            <Button icon="pi pi-ellipsis-v" class="p-button-text p-button-secondary" @click="toggleMenu($event, slotProps.data)" />
                        </template>
                    </Column>
                </DataTable>
                <Menu ref="menu" :model="actionItems" :popup="true" />
                <Paginator
                    v-show="!loading"
                    :rows="perPage"
                    :totalRecords="total"
                    v-model:first="page"
                    @page="onPage($event)"
                    template="PrevPageLink PageLinks NextPageLink CurrentPageReport"
                    currentPageReportTemplate="Página {currentPage} de {totalPages}"
                >
                </Paginator>

                <Dialog v-model:visible="deleteRecordDialog" :style="{ width: '450px' }" header="Confirmar" :modal="true">
                    <div class="flex align-items-center justify-content-center">
                        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                        <span v-if="record"
                            >Tem certeza que deseja excluir <b>{{ record.name }}</b
                            >?</span
                        >
                    </div>
                    <template #footer>
                        <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="deleteRecordDialog = false" />
                        <Button label="Excluir" icon="pi pi-trash" class="p-button-outlined p-button-danger" @click="deleteRecord" />
                    </template>
                </Dialog>
            </div>
        </div>
    </div>
</template>

<script>
import AppInfoManual from '../../components/AppInfoManual.vue';
import BaseService from '../../services/BaseService';
import { getCurrentCompany } from '@/services/store';

export default {
    data() {
        return {
            actionItems: [
                {
                    label: 'Alterar',
                    icon: 'pi pi-pencil',
                    command: () => this.editRecord(this.actionRecord.id)
                },
                {
                    label: 'Excluir',
                    icon: 'pi pi-trash',
                    command: () => this.confirmDeleteRecord(this.actionRecord)
                }
            ],
            records: [],
            loading: false,
            page: 1,
            total: 0,
            totalPage: 0,
            perPage: 10,
            recordDialog: false,
            deleteRecordDialog: false,
            filter: []
        };
    },
    mounted() {
        this.$service = new BaseService('/medics');
        this.load();
    },
    computed: {
        tenant() {
            return getCurrentCompany();
        }
    },
    watch: {
        tenant() {
            this.load();
        }
    },
    methods: {
        toggleMenu(event, data) {
            this.actionRecord = data;
            this.$refs.menu.toggle(event);
        },
        editRecord(id) {
            this.$router.push('/medic/' + id);
        },
        confirmDeleteRecord(record) {
            this.record = record;
            this.deleteRecordDialog = true;
        },
        async deleteRecord() {
            if (this.record) {
                try {
                    await this.$service.remove(this.record.id);
                    this.record = {};
                    this.load();
                    this.deleteRecordDialog = false;
                    this.$toast.add({ severity: 'success', summary: 'Médico excluído com sucesso!', life: 3000 });
                } catch (err) {
                    console.error(err);
                    this.$toast.add({ severity: 'error', summary: 'Erro ao excluir o médico!', life: 3000 });
                }
            }
        },
        async load() {
            this.loading = true;
            const { data } = await this.$service.findAll({ limit: this.perPage, page: this.page, filter: this.filter });
            this.records = data.items;
            this.total = data.meta?.totalItems;
            this.totalPage = data.meta?.totalPages;
            this.loading = false;
        },
        async onPage(event) {
            const { data } = await this.$service.findAll({ limit: this.perPage, page: event.page + 1, filter: this.filter });
            this.records = data.items;
            this.total = data.meta?.totalItems;
            this.totalPage = data.meta?.totalPages;
        }
    },
    components: { AppInfoManual }
};
</script>

